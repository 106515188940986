/* eslint-disable react/display-name */
import React from "react";
import { Helmet } from "react-helmet";
import { graphql, withPrefix } from "gatsby";
import { Global, css, useTheme } from "@emotion/react";
import { lightTheme } from "../styles/themes";
import { globalStyle } from "../styles/globalStyle";

import hookIcon from "../images/hook-l-icon.svg";
import mapIcon from "../images/map-l-icon.svg";
import shipIcon from "../images/ship-l-icon.svg";
import hookIconD from "../images/hook-d-icon.svg";
import mapIconD from "../images/map-d-icon.svg";
import shipIconD from "../images/ship-d-icon.svg";
import darkBlueLogo from "../images/new-pathwar-logo-dark-blue.svg";
import colorsLogo from "../images/new_pathwar-logo.svg";
import footerLogo from "../images/new-pathwar-logo-grey.svg";
import footerLogoD from "../images/new-pathwar-logo-light-purple.svg";

import islandLeadingBg from "../images/island-light-mode-illustration.svg";
import islandLeadingBgDark from "../images/landing-island-darkmode-illustration.svg";
import ProvidersWrapper from "../components/ProvidersWrapper";
import { FormattedMessage } from "react-intl";

const logoWrapper = css`
  width: fit-content;

  img {
    width: 91px;
    height: 94px;
  }

  @media (max-width: 991px) {
    text-align: center;
    width: 100%;

    img {
      width: 51px;
      height: 52px;
    }
  }
`;

const leading = ({ type, colors }) => {
  const isDark = type === "dark";

  return css`
    background-color: ${isDark ? colors.primary : colors.light};
    height: 813px;
    background-image: url(${isDark ? islandLeadingBgDark : islandLeadingBg});
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 35px;

    @media (max-width: 991px) {
      height: 706px;
      padding-top: 25px;
    }
  `;
};

const leadingContent = ({ colors, type }) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  width: 40%;
  height: 640px;

  .title-block,
  .sub-block {
    margin-bottom: 2.5rem;
  }

  .cta-block {
    color: ${type === "dark" ? colors.secondary : colors.tertiary};
    text-align: center;
    display: flex;
    flex-direction: column;

    a {
      color: ${type === "dark" ? colors.tertiary : colors.primary};
    }

    .custom-button {
      margin-bottom: 1rem;
      color: ${type === "dark" ? colors.primary : colors.light};
    }
  }

  @media (max-width: 991px) {
    height: auto;
    width: 100%;

    .title-block,
    .sub-block {
      margin-bottom: 1rem;
    }

    .title-block {
      margin-top: 2.812rem;
    }

    .cta-block {
      text-align: left;
    }
  }
`;

const cardsArea = ({ shadows, colors, type }) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 100%;
  top: -70px;

  .site-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #fff;
    max-width: 358px;
    min-height: 389px;
    padding: 40px 40px 30px;
    box-shadow: ${shadows.card};
    color: ${type === "dark" ? colors.primary : "inherit"};
    border-radius: 6px;

    img {
      margin-bottom: 35px;
    }

    h3 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 25px;
    }
  }

  @media (max-width: 991px) {
    flex-direction: column;

    .site-card {
      max-width: 270px;
      min-height: 264px;
      margin-bottom: 1.875rem;
    }
  }
`;

const footer = ({ colors, type }) => css`
  display: flex;
  align-items: self-end;
  justify-content: space-around;
  background-color: ${type === "dark" ? colors.dark : colors.light};
  color: ${type === "dark" ? colors.secondary : colors.tertiary};
  padding: 40px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    color: inherit;
  }

  .data-col {
    max-width: 150px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .data-col {
      margin-bottom: 1.8rem;
    }
  }
`;

const IndexPage = ({ data }) => {
  const currentTheme = useTheme();
  const isDark = currentTheme.type === "dark";
  const title = data.site.siteMetadata.title;
  const description = data.site.siteMetadata.description;
  const featuredImage = `https://www.pathwar.land/images/new_pathwar-logo.png`;
  const comingsoon = process.env.GATSBY_COMINGSOON === "true";
  const headerLogo = isDark ? colorsLogo : darkBlueLogo;

  // const appKeycloakRealm = process.env.GATSBY_KEYCLOAK_REALM;
  // const appKeycloakBaseUrl = process.env.GATSBY_KEYCLOAK_BASE_URL;
  const appConsoleUrl = process.env.GATSBY_CONSOLE_APP_URL;

  return (
    <>
      <Global styles={globalStyle(currentTheme)} />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta
          name="go-import"
          content="pathwar.land git https://github.com/pathwar/pathwar"
        />
        <meta
          name="go-source"
          content="pathwar.land https://github.com/pathwar/pathwar https://github.com/pathwar/pathwar/tree/master{/dir} https://github.com/pathwar/pathwar/tree/master{/dir}/{file}#L{line}"
        />

        <meta property="og:description" content={description} />
        <meta property="og:url" content={description} />
        <meta property="og:site_name" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={featuredImage} />
        <meta property="og:image:width" content={"200px"} />
        <meta property="og:image:height" content={"200px"} />

        <meta property="twitter:card" content="summary" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={featuredImage} />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Bungee&display=swap"
          rel="stylesheet"
        />
        <script async src={withPrefix("chat-init.js")} type="text/javascript" />
      </Helmet>

      <section className="siteContainer" css={theme => leading(theme)}>
        <span css={logoWrapper}>
          <img src={headerLogo} alt="Pathwar Logo" />
        </span>
        <div css={theme => leadingContent(theme)}>
          <div className="title-block">
            <h1>
              <FormattedMessage id="HomePage.leading" />
            </h1>
          </div>
          <div className="sub-block">
            <h2>
              <FormattedMessage id="HomePage.description" />
            </h2>
          </div>
          {comingsoon && (
            <h3>
              <FormattedMessage id="HomePage.comingsoon" />
              ...
            </h3>
          )}
          {!comingsoon && (
            <div className="cta-block">
              <a
                href={`${appConsoleUrl}`}
                className="custom-button"
                alt="Join the adventure!"
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="HomePage.cta" />!
              </a>
              <p>
                <FormattedMessage id="HomePage.loginCta" />{" "}
                <a href={`${appConsoleUrl}`} alt="Login">
                  Login
                </a>
              </p>
            </div>
          )}
        </div>
      </section>

      {!comingsoon && (
        <>
          <section className="siteContainer" css={theme => cardsArea(theme)}>
            <div className="site-card">
              <img src={isDark ? shipIconD : shipIcon} />
              <h3>
                <FormattedMessage id="HomePage.card1.title" />
              </h3>
              <p>
                <FormattedMessage id="HomePage.card1.description" />
              </p>
            </div>
            <div className="site-card">
              <img src={isDark ? mapIconD : mapIcon} />
              <h3>
                <FormattedMessage id="HomePage.card2.title" />
              </h3>
              <p>
                <FormattedMessage id="HomePage.card2.description" />
              </p>
            </div>
            <div className="site-card">
              <img src={isDark ? hookIconD : hookIcon} />
              <h3>
                <FormattedMessage id="HomePage.card3.title" />
              </h3>
              <p>
                <FormattedMessage id="HomePage.card3.description" />
              </p>
              <a
                href="https://github.com/pathwar/pathwar/blob/master/CODE_OF_CONDUCT.md"
                target="_blank"
                rel="noreferrer noopener"
                className="custom-button outline"
              >
                <FormattedMessage id="HomePage.card3.btnCta" />
              </a>
            </div>
          </section>
        </>
      )}

      <footer css={theme => footer(theme)}>
        <img src={isDark ? footerLogoD : footerLogo} />
        <p>
          © 2015-2022 Pathwar Staff Licensed under the Apache License, Version
          2.0
        </p>
        {/* <div className="data-col">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                eget est molestie est tincidunt varius. Suspendisse quis
                elementum odio, vitae euismod sem.
              </p>
            </div> */}
        {/* <div className="data-col">
              <ul>
                <li>
                  <a href="#">CGU</a>
                </li>
                <li>
                  <a href="#">42</a>
                </li>
                <li>
                  <a href="#">Lorem lien 01</a>
                </li>
                <li>
                  <a href="#">Lorem lien 02</a>
                </li>
              </ul>
            </div>
            <div className="data-col">
              <ul>
                <li>
                  <a href="#">Lorem lien 03</a>
                </li>
                <li>
                  <a href="#">Lorem lien 04</a>
                </li>
                <li>
                  <a href="#">Lorem lien 05</a>
                </li>
                <li>
                  <a href="#">Lorem lien 06</a>
                </li>
              </ul>
            </div>
            <div className="data-col">
              <ul>
                <li>
                  <a href="#">Lorem lien 07</a>
                </li>
                <li>
                  <a href="#">Lorem lien 08</a>
                </li>
              </ul>
            </div>
            <div className="data-col">
              <p>Emplacement pour RS ou autre si besoin</p>
            </div> */}
      </footer>
    </>
  );
};

const SiteRoot = ({ data }) => {
  const themeToUse = lightTheme;

  return (
    <ProvidersWrapper theme={themeToUse}>
      <IndexPage data={data} />
    </ProvidersWrapper>
  );
};

export default SiteRoot;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;
